import { Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import { useIntl, defineMessages } from 'react-intl'
import cx from 'classnames'
import styles from './AdventureCard.module.scss'

const messages = defineMessages({
  instantConfirmation: {
    id: 'instantConfirmation',
    defaultMessage: 'Instant confirmation',
  },
})

function InstantConfirmation() {
  const intl = useIntl()
  return (
    <Fragment>
      <i
        className={cx(styles.iconLightning, 'font-icons icon-lightning')}
        data-tip={intl.formatMessage(messages.instantConfirmation)}
      />
      <ReactTooltip effect='solid' />
    </Fragment>
  )
}
export default InstantConfirmation
