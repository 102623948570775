import styles from './GitBranch.module.scss'
import cx from 'classnames'
import { useState } from 'react'
import moment from 'moment'

export default function GitBranch() {
  const [active, setActive] = useState(true)
  const close = () => {
    setActive(false)
  }
  if(!process.env.NEXT_PUBLIC_SHOW_BRANCH || !active) {
    return null
  }

  return <div className={styles.container}>
    <div className={styles.wrapper} suppressHydrationWarning>
      GIT BRANCH: { process.env.GIT_BRANCH }&nbsp;
      (Build time: { moment(process.env.BUILD_TIME).format('lll') })
      <i onClick={close} className={cx(styles.icon, 'font-icons close-icon')} />
    </div>
  </div>
}
