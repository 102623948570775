import each from 'lodash/each'
import { serializeData, getCookie, getIdToken } from './utils'
import { toast } from 'react-toastify'
import isPlainObject from 'lodash/isPlainObject'
import upperFirst from 'lodash/upperFirst'

export const fetcher = (url) => api(url).get()
export const fetchWithParams = ({url, params}) => api(url).get(params)
interface ResponseError extends Error {
  status?: number
}

interface Cookies {
  [key: string]: string
}

export const defaultQueryFetch = ({ queryKey }) => {
  const [url, params] = queryKey
  return api(url, params?.locale).get(params)
}

export const queryMutate = (url, method, params) => api(url)[method](params)


export default function api(endpoint: string,
  language?: string,
  signal?: AbortSignal,
  addTokenToRequest?: boolean,
  cookies?: Cookies,
  endpointAsUrl?: boolean,
  currencyHeader?: string,
  orderTokenAuth?: string) {
  return new API(
    endpoint,
    language,
    signal,
    cookies,
    addTokenToRequest,
    endpointAsUrl,
    currencyHeader,
    orderTokenAuth,
  )
}

const handlerError = (error) => {
  each(error, function(errorMessage, key) {
    if(key === 'nonFieldErrors' || key === 'isDraft' || key === '__all__') {
      toast.error(
          Array.isArray(errorMessage) ? errorMessage[0] : errorMessage,
          {
            position: toast.POSITION.TOP_CENTER,
          },
      )
    } else if(isPlainObject(errorMessage)) {
      handlerError(errorMessage)
    } else if(key) {
      const modifiedKey = key
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .split(' ')
        .map((word, index) => index === 0 ? upperFirst(word) : word.toLowerCase())
        .join(' ')

      toast.error(`${modifiedKey} : ${Array.isArray(errorMessage) ? errorMessage[0] : errorMessage}`, {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  })

  return error
}

const domain = process.env.NEXT_PUBLIC_TRAVEL_DOMAIN

class API {
  endpoint: string
  language: string
  signal: AbortSignal
  cookies: Cookies
  addTokenToRequest: boolean
  endpointAsUrl: boolean
  currencyHeader: string
  orderTokenAuth: string
  notFoundEror: string
  constructor(
    endpoint: string,
    language: string,
    signal: AbortSignal,
    cookies: Cookies,
    addTokenToRequest: boolean,
    endpointAsUrl: boolean,
    currencyHeader: string,
    orderTokenAuth: string,
  ) {
    this.endpoint = endpoint
    this.language = language
    this.signal = signal
    this.cookies = cookies
    this.addTokenToRequest = addTokenToRequest
    this.endpointAsUrl = endpointAsUrl
    this.currencyHeader = currencyHeader
    this.orderTokenAuth = orderTokenAuth
  }

  getUrl() {
    if(this.endpoint.includes('https://')) {
      return this.endpoint
    } else if(this.endpoint.includes('/api/adventure/v1')) {
      return `${domain}${this.endpoint}`
    }
    return this.endpointAsUrl
        ? this.endpoint
        : `${domain}/api/v2/travel/${this.endpoint}`
  }


  request(method = 'GET', data, options: any = {}): Promise<any> {
    let formData
    const authToken = getIdToken(this.cookies)
    const isPadiAPI =
      this.endpoint.includes('auth/api/oauth/refresh') ||
      this.endpoint.includes('acquia-stage.padi.com') ||
      this.endpoint.includes('api-ecomm.')
    const Authorization = authToken && !isPadiAPI ? 'Bearer ' + authToken : ''
    let url = this.getUrl()
    url += url.endsWith('/') || url.endsWith('.json') ? '' : '/'

    if(method === 'GET' && data) {
      url += `?${serializeData(data)}`
    }

    let headers: HeadersInit = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Next-Accept-Language': this.language,
    }

    if(Authorization) {
      headers.Authorization = Authorization
    }

    if(this.orderTokenAuth) {
      headers.Authorization = `Order ${this.orderTokenAuth}`
    }

    if(!isPadiAPI) {
      headers['X-CSRFToken'] = getCookie('csrftoken', this.cookies)
    }

    if(this.currencyHeader) {
      headers = {
        ...headers,
        'Currency-code': this.currencyHeader,
      }
    }

    if(options.isFormData) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { 'Content-Type': toDelete, ...newHeaders } = headers
      headers = newHeaders
      if(window) {
        formData = new window.FormData()
      }

      for(const fieldName in data) {
        formData.append(fieldName, data[fieldName])
      }
    }
    if(!this.language) {
      delete headers['Next-Accept-Language']
    }

    const params: RequestInit = {
      method,
      body:
          data && method !== 'GET'
            ? (options.isFormData
              ? formData
              : JSON.stringify(data))
            : undefined,
      headers,
      signal: this.signal,
      credentials: isPadiAPI ? 'omit' : 'include',
    }
    return fetch(url, params)
      .then((response) => {
        if(response.status === 500) {
          // toast.error('Server error. Please try again later.', {
          //   position: toast.POSITION.TOP_CENTER,
          // })
          const error = new Error('Error 500') as ResponseError
          error.status = 500
          throw error
        }

        if(response.status === 401) {
          // store.dispatch(logout());
          window.localStorage.removeItem('user')
          window.localStorage.removeItem('token')

          //   if (window.location.pathname.search(createURL('login')) >= 0) {
          //     store.dispatch(push(createURL('login')));
          //     window.location.reload();
          //   }
        }

        if(response.status === 400) {
          return response.json().then((error) => {
            handlerError(error)
            if(response.ok) {
              return error
            }

            // eslint-disable-next-line no-throw-literal
            throw { status: response.status, error }
          })
        }

        if(response.status === 403) {
          throw new Error('403')
        }

        if(response.status === 404) {
          // notification.error({
          //   message: 'Not found',
          //   style: {
          //     width: 300,
          //     textTransform: 'uppercase',
          //     marginLeft: 335 - 300,
          //   },
          // });
          return response.json().then(function(error) {
            // eslint-disable-next-line no-throw-literal
            throw { status: 404, error }
          })
        }

        const contentTypeHeader =
          response.headers && response.headers.get('Content-Type')
        if(
          response.status === 204 ||
          !contentTypeHeader ||
          (contentTypeHeader &&
            contentTypeHeader.search('application/json') === -1)
        ) {
          return response
        }

        return response.json().then(function(body) {
          if(response.ok) {
            return body
          }
          handlerError(body)
          throw body
        })
      })
  }

  postAsFormData(data?: object) {
    return this.request('POST', data, { isFormData: true })
  }

  patchAsFormData(data?: object) {
    return this.request('PATCH', data, { isFormData: true })
  }

  putAsFormData(data?: object) {
    return this.request('PUT', data, { isFormData: true })
  }

  post(data?: object) {
    return this.request('POST', data)
  }

  delete(data?: object) {
    return this.request('DELETE', data)
  }

  patch(data?: object) {
    return this.request('PATCH', data)
  }

  put(data?: object) {
    return this.request('PUT', data)
  }

  get(data?: object) {
    return this.request('GET', data)
  }

  set404ErrorMsg(error) {
    this.notFoundEror = error

    return this
  }

  all(requests) {
    return Promise.all(requests).catch((body) => {
      return body.errors
    })
  }
}
