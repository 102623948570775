import get from 'lodash/get'
import { useSelector } from 'react-redux'
import ActivityInclusions from './ActivityInclusions'
import PriceConverter from '../../helpers/PriceConverter'

import AvailableDates from './AvailableDate'
import InstantConfirmation from './InstantConfirmation'
import WishlistButton from '../../helpers/WishlistButton'

import styles from './AdventureCard.module.scss'
import { IAdventureItem } from 'interfaces'
import { RootState } from 'redux/store'

type Props = {
  adventure: IAdventureItem
  isWidget?: boolean
}

export default function AdventureCard({ adventure, isWidget }: Props) {
  const selectedCurrency = useSelector((state: RootState) => state.selectedCurrency)
  const picture = get(adventure, 'picture["600x360"]', '')
  const currency = get(adventure, 'minimumPrice.originCurrency')
  const compareAtPrice = get(adventure, 'compareAtPrice.originValue')
  const price = get(adventure, 'minimumPrice.originValue')
  const handleClick = () => {
    if(adventure.impression) {
      window.dataLayer.push({
        event: 'productClick',
        ecommerce: {
          currencyCode: selectedCurrency,
          click: {
            actionField: { list: adventure.actionField },
            products: [adventure.impression],
          },
        },
      })
    }
  }

  const prevQueryParams = window.location.search
    ? window.location.search.replace('?', '&')
    : ''

  return (
    <div className={styles.card}>
      <a
        href={`${adventure.activityPage}${
          isWidget ? prevQueryParams + '&widget=true' : prevQueryParams
        }`}
        title={`${adventure.title} landing page`}
        onClick={handleClick}
        className={styles.link}
      />
      <div className={styles.imgWrapper}>
        <img
          src={picture}
          srcSet={`${picture} 2x, ${adventure?.picture?.['300x180']} 1x`}
          className={styles.img}
          alt={`${adventure.title} picture`}
          loading='lazy'
        />
        {!isWidget &&
          <WishlistButton
            isInWishlistInit={adventure.inDefaultWishlist}
            type='Activity'
            id={adventure.id}
          />
        }
      </div>

      <div className={styles.info}>
        <strong className={styles.title}>{adventure.title}</strong>
        <p className={styles.dcTitle}>{adventure.diveCenterTitle}</p>
        <p className={styles.inclusion}>
          <ActivityInclusions item={adventure} />
        </p>
        <p className={styles.availability}>
          <AvailableDates date={adventure.availableDate} />
        </p>
        <div className={styles.priceWrapper}>
          {compareAtPrice > price && (
            <span className={styles.oldPrice}>
              <PriceConverter
                amount={compareAtPrice}
                currency={currency}
                conversionPercent
              />
            </span>
          )}
          <span>
            <PriceConverter
              amount={price}
              currency={currency}
              conversionPercent
            />
            {adventure.instantConfirmation && <InstantConfirmation />}
          </span>
        </div>
      </div>
    </div>
  )
}
