import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import { createPadiUrl } from '@/helpers/routes'

import homepageStyles from '../HomePage.module.scss'
import styles from './FeaturedDestinations.module.scss'
import { IFeaturedDestination } from '../../../interfaces'

export default function FeaturedDestinations({ destinations }: { destinations: IFeaturedDestination[] }) {
  return <section className={homepageStyles.section}>
      <h2 className={homepageStyles.sectionTitle}>
        <FormattedMessage id='destinations_guides' defaultMessage='Destination guides' />
      </h2>
      <p className={homepageStyles.sectionDescription}>
        <FormattedMessage id='300_scuba_guides' defaultMessage={'We have 300 scuba guides to the world\'s best scuba diving.'} />
      </p>
      <div className={styles.destinationWrapper}>
        {
          destinations.map((destination, index) => {
            const image2x = index > 1 ? destination.thumbnails.FeaturedDestinations840x520 : destination.thumbnails.FeaturedDestinations1080x900
            const image = index > 1 ? destination.thumbnails.FeaturedDestinations420x260 : destination.thumbnails.FeaturedDestinations640x450
            return (<a className={styles.item} href={destination.url} key={destination.title}>
                <img loading='lazy' className={styles.img} alt='featured destination'
                 src={image2x}
                 srcSet={`${image2x} 2x, ${image} 1x`}/>

                <span className={styles.overlay}></span>
                <span className={styles.title}>{destination.title}</span>
            </a>)
          })
        }
      </div>
      <div>
        <a className={homepageStyles.link} href={createPadiUrl('destinations')}>
          <FormattedMessage id='see_all_guides' defaultMessage='See all guides' />
          <i className={cx(homepageStyles.rightArrowIcon, 'font-icons right-arrow-icon')} />
        </a>
      </div>
    </section>
}
