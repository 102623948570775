import { useEffect, useState } from 'react'
import API from '@/helpers/api'
import { FormattedMessage } from 'react-intl'
// import { getCoordinates } from '@/helpers/utils'
import get from 'lodash/get'
import homepageStyles from '../HomePage.module.scss'
import AdventuresSlider from '../../adventures/AdventuresSlider'
import styles from './AdventuresNearby.module.scss'
import { createUrl } from '@/helpers/routes'
import cx from 'classnames'

export default function AdventuresNearby() {
  const [locationInfo, setLocationInfo] = useState([])
  const [adventures, setAdventures] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [nothingFound, setNothingFound] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    // getCoordinates((coords) => {
    getAdventures()
    // }, getAdventures)
  }, [])

  function getAdventures(coords) {
    const params = {
      uniq_activity: true,
      point: coords ? `${coords.latitude},${coords.longitude}` : undefined,
    }
    Promise.all([
      API('/api/adventure/v1/search/location-info/nearby/').get(params),
      API('/api/adventure/v1/search/activities/nearby/').get(params),
    ]).then(([location, activites]) => {
      const mappedLocation = Object.entries(location)
      setLocationInfo(mappedLocation[0])
      if(get(activites, 'results.length')) {
        setAdventures(activites.results)
        setIsLoading(false)
      } else {
        getAdventuresByLocations(mappedLocation, 0)
      }
    })
  }

  function getAdventuresByLocations(location, locationLevel) {
    const model = location[locationLevel]
    setNothingFound(true)
    if(model) {
      API(`/api/adventure/v1/search/${model[0]}/${model[1].pk}/activities/`)
        .get({
          uniq_activity: true,
        })
        .then((activites) => {
          if(get(activites, 'results.length')) {
            setIsLoading(false)
            setAdventures(activites.results)
          } else {
            getAdventuresByLocations(location, locationLevel + 1)
          }
        })
    }
  }

  const locationTitle = get(locationInfo, '[1].inTitle')
  return (
    <section className={homepageStyles.section}>
      <h2 className={homepageStyles.sectionTitle}>
        {isLoading
          ? (<FormattedMessage
            id='bookable_adventures_near_you'
            defaultMessage='Bookable adventures nearby'
          />)
          : (<FormattedMessage
            id='bookable_adventures_nearby'
            defaultMessage='Bookable adventures nearby {titleIn}'
            values={{ titleIn: locationTitle }}
          />)
        }
      </h2>
      <p className={homepageStyles.sectionDescription}>
        <FormattedMessage
          id='find_andventure_fun_diving'
          defaultMessage='Find and book courses, fun dives and more'
        />
      </p>
      <div className={homepageStyles.sliderWrapper}>
        {nothingFound && !isLoading && (
          <span className={styles.notFound} data-testid='not-found'>
            <FormattedMessage
              id='activities.not-found'
              defaultMessage='We couldn’t find any activities near you, but you might like these options.'
            />
          </span>
        )}
        <AdventuresSlider items={adventures} isLoading={isLoading} />
      </div>
      <a href={createUrl('adventures')} className={homepageStyles.link}>
        <FormattedMessage id='scuba_diving_vacations' defaultMessage='PADI Travel | Scuba Diving Vacations' />
        <i className={cx(homepageStyles.rightArrowIcon, 'font-icons right-arrow-icon')} />
      </a>
    </section>
  )
}
