import { FormattedMessage } from 'react-intl'
import cx from 'classnames'

import homepageStyles from '../HomePage.module.scss'
import styles from './SeekAdventures.module.scss'
import Picture from '@/helpers/Picture'

export default function SeekAdventures() {
  return (<div className='blue-bg'>
    <section className={cx(homepageStyles.section, 'ptn pbn')}>
      <div className={styles.wrapper}>
        <div className={styles.textWrapper}>
          <h2 className={homepageStyles.sectionTitleSmall}><FormattedMessage id='seek.title'
            defaultMessage='Want to Seek Adventure and Save the Ocean?'
          /></h2>
          <p className={styles.text}><FormattedMessage id='seek.text'
            defaultMessage='When you choose PADI Travel, you can support PADI Divers all over the world taking local action for global conservation impact. By donating to PADI AWARE’s Community Grant Program, you can save sharks, clean up our oceans, combat climate change, restore coral reefs and create new marine protected areas all in one easy, convenient step.'
          /></p>
          <p className={styles.text}><FormattedMessage id='seek.checokout' defaultMessage='Available at checkout.' />
          </p>
        </div>
        <div className={styles.imgWrapper}>
          <Picture availableSizes={[1024]}
            wepbSrc='/with-posters.webp'
            alt='Seagrass image'
            src='/with-posters.jpg'
            width={750}
            imageStyles={styles.image}
          />
        </div>
      </div>
    </section>
  </div>)
}
