import styles from './ShopTypes.module.scss'
import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import { createUrl } from '../../../../helpers/routes'

type ShopItem = {
  type: string
  title: JSX.Element
  className: string
  icon: string
  link: string
}

const getList = ():ShopItem[] => [
  {
    type: 'liveaboards',
    title: <FormattedMessage id='liveboards' defaultMessage='Liveaboards' />,
    className: 'liveaboardIcon',
    icon: 'liveaboard-icon',
    link: createUrl('search_la', { slug: 'all' }),
  },
  {
    type: 'dive_resorts',
    title: <FormattedMessage id='dive_resorts' defaultMessage='Dive resorts' />,
    className: 'diveResortIcon',
    icon: 'dive-resort-icon',
    link: createUrl('search_dr', { slug: 'all' }),
  },
  {
    type: 'dive_centers',
    title: <FormattedMessage id='dive_centers' defaultMessage='Dive centers' />,
    className: 'iconDiveCenter',
    icon: 'icon-dive-center',
    link: createUrl('search_dc', { slug: 'all' }),
  },
  {
    type: 'dives',
    title: <FormattedMessage id='diving' defaultMessage='Diving' />,
    className: 'iconDiver',
    icon: 'icon-diver',
    link: createUrl('search_diving', { slug: 'all' }),
  },
  {
    type: 'courses',
    title: <FormattedMessage id='courses' defaultMessage='Courses' />,
    className: 'iconCourse',
    icon: 'icon-course',
    link: createUrl('search_courses', { slug: 'all' }),
  },
  {
    type: 'snorkelling',
    title: <FormattedMessage id='snorkeling' defaultMessage='Snorkeling' />,
    className: 'iconSnorkeling',
    icon: 'icon-snorkeling',
    link: createUrl('search_snorkeling', { slug: 'all' }),
  },
]

type ShopTypeSelectorProps = {
  data: ShopItem
  onClick?: () => void
}

const ShopTypeSelector = ({ data, onClick }: ShopTypeSelectorProps) => (
  <a
    className={styles.shopType}
    href={data.link}
    onClick={onClick}
  >
    <i className={cx('font-icons', styles.icon, data.icon, styles[data.className])} />
    <span className={styles.title}>{data.title}</span>
  </a>
)

const SearchTypesList = () => {
  const list = getList()
  return (
    <Fragment>
      {list.map((item) => (
        <ShopTypeSelector
          key={item.type}
          data={item}
        />
      ))}
    </Fragment>
  )
}

export default function ShopTypes() {
  return <div className={styles.container}>
    <SearchTypesList />
  </div>
}
