import { Promotion } from 'interfaces/BestDeals'
import { FormattedMessage, defineMessages } from 'react-intl'
import PriceConverter from './PriceConverter'
import styles from './PromotionLabel.module.scss'

const msg = defineMessages({
  promotion_save_qty: {
    id: 'promotion_save_qty',
    defaultMessage: 'Save {qty}',
  },
  promotion_save_nights: {
    id: 'promotion_save_nights',
    defaultMessage: '{freeNights, plural, one {# night free} other {# nights free}}',
  },
  promotion_deal: {
    id: 'promotion_deal',
    defaultMessage: 'Deal',
  },
})

type PromotionLabelProps = {
  promotion: Promotion
  price: number | string
  compareAtPrice: number | string
  currency: string
  showComparePrice: boolean
}

function getLabel({ promotion, price, compareAtPrice, currency, showComparePrice }:PromotionLabelProps) {
  if(promotion) {
    switch (promotion.kind) {
      case 10:
        return {
          ...msg.promotion_save_qty,
          values: {
            qty: <PriceConverter noCurrency amount={Math.round(Number(compareAtPrice) - Number(price))} currency={currency} />,
          },
        }
      case 20:
        return {
          ...msg.promotion_save_qty,
          values: {
            qty: <PriceConverter noCurrency amount={Math.round(((Number(price) / (100 - promotion.value)) * 100) - Number(price))} currency={currency} />,
          },
        }
      case 30:
        return {
          ...msg.promotion_save_nights,
          values: {
            freeNights: promotion.value,
          },
        }
      case 40:
        return {
          ...msg.promotion_deal,
        }
    }
  } else if(compareAtPrice > price && showComparePrice) {
    return {
      ...msg.promotion_save_qty,
      values: {
        qty: <PriceConverter noCurrency amount={Math.round(Number(compareAtPrice) - Number(price))} currency={currency} />,
      },
    }
  }
}
export default function PromotionLabel({ promotion, price, compareAtPrice, currency, showComparePrice }: PromotionLabelProps) {
  const message = getLabel({ promotion, price, compareAtPrice, currency, showComparePrice })

  if(!message) {
    return null
  }

  return (<span className={styles.dealLabel}>
    <FormattedMessage {...message} />
  </span>)
}
