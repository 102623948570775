import { useRouter } from 'next/router'
import styles from './TrustPilot.module.scss'
import homePageStyles from '../components/homepage/HomePage.module.scss'
import cx from 'classnames'

export default function TrustPilot() {
  const { locale } = useRouter()

  return (
    <div className={cx(styles.trustpilotWidget, homePageStyles.section, 'trustpilot-widget')}
         data-locale={locale}
         data-template-id='54ad5defc6454f065c28af8b'
         data-businessunit-id='55eeddbb0000ff00058313b3'
         data-style-height='240px'
         data-style-width='100%'
         data-theme='light'
         data-stars='5,4'>
      <a href='https://www.trustpilot.com/review/travel.padi.com' target='_blank' rel='noreferrer'>Trustpilot</a>
    </div>
  )
}
