import { FormattedMessage } from 'react-intl'
import homepageStyles from '../HomePage.module.scss'
import styles from './Banner.module.scss'
import cx from 'classnames'
import Picture from '@/helpers/Picture'

export default function Banner() {
  return (<section className={cx(homepageStyles.section, 'ptn pbn')}>
    <div className={styles.container}>
      <div className={styles.item}>
        <i className={cx(styles.icon, 'font-icons money-icon')} />
        <p className={styles.title}>
          <FormattedMessage id='best_price' defaultMessage='Best price guarantee' />
        </p>
      </div>
      <div className={styles.item}>
        <i className={cx(styles.icon, styles.iconInsurance, 'font-icons icon-shield-approve')} />
        <p className={styles.title}>
          <FormattedMessage id='free_insurance' defaultMessage='Free dive insurance' />
        </p>
      </div>
      <div className={styles.item}>
        <i className={cx(styles.icon, styles.iconInformation, 'font-icons icon-information')} />
        <p className={styles.title}>
          <FormattedMessage id='advice_from_real' defaultMessage='Expert advice from real divers' />
        </p>
      </div>
      <div className={styles.item}>
        <span className={styles.logo}>
          <Picture src='/padi-logo-with-r-mark.svg' availableSizes={[]} alt='Dive with confidence'/>
        </span>
        <p className={styles.title}>
          <FormattedMessage id='dive_with_confidence' defaultMessage='Dive with confidence' />
          </p>
      </div>
    </div>
  </section>)
}
