import { useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import Slider, { LazyLoadTypes } from 'react-slick'
import PriceConverter, { convertCurrency } from '@/helpers/PriceConverter'
import PromotionLabel from '@/helpers/PromotionLabel'
import TwoDatesFormat from '@/helpers/TwoDatesFormat'
import { target, roundPrice, getKindVerbose } from '@/helpers/utils'
import get from 'lodash/get'
import cx from 'classnames'
import { pushToDataLayer } from '@/helpers/gtm'

import styles from './SpecialDeals.module.scss'
import { IBestDeals } from '../../../interfaces/BestDeals'

export default function SpecialDeals({ deals, eCommerceListName }: { deals: IBestDeals; eCommerceListName: string}) {
  const ref = useRef()
  useEffect(function() {
    deals.results.forEach((operator, index) => {
      const { promotion, shopTitle, dateFrom, dateTo } = operator
      operator.impression = {
        name: shopTitle,
        id: operator.shopId,
        price: roundPrice(
          convertCurrency(operator.price, operator.currency, 'USD'),
        ),
        brand: `${shopTitle}|${
              operator.affiliateId || 'Non-PADI Operator'
            }`,
        category: getKindVerbose(operator.shopKind),
        variant: `${promotion ? promotion.title : 'Deals'} / ${moment(
              dateFrom,
            ).format('DD MMM')} - ${moment(dateTo).format('DD MMM')}`,
        list: eCommerceListName,
        position: index + 1,
      }
    })
  }, [deals])

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    dots: true,
    lazyLoad: 'progressive' as LazyLoadTypes, 
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 3 } },
      {
        breakpoint: 769,
        settings: { slidesToShow: 2.5, slidesToScroll: 1, arrows: false },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1.8, slidesToScroll: 1, arrows: false },
      },
      {
        breakpoint: 481,
        settings: { slidesToShow: 1.2, slidesToScroll: 1, arrows: false },
      },
    ],
    beforeChange: (a, b) => {
      const difference = b + Math.abs(a - b)
      pushToDataLayer({
        event: 'impression',
        ecommerce: {
          currencyCode: 'USD',
          impressions: deals.results
            .slice(b, difference)
            .map((item) => item.impression),
        },
      })
    },
  }

  useEffect(() => {
    const numberOfItemsToShow = get(
      ref,
      'current.innerSlider.props.slidesToShow',
    )
    if(numberOfItemsToShow && deals.results.length) {
      pushToDataLayer({
        event: 'impression',
        ecommerce: {
          currencyCode: 'USD',
          impressions: deals.results
            .slice(0, numberOfItemsToShow)
            .map((item) => item.impression),
        },
      })
    }
  }, [ref.current, deals.results.length])

  return (
    <Slider
      ref={ref}
      className='slick-pt-custom space-between same-height'
      {...settings}
    >
      {deals.results.map((shop) => {
        const handleClick = () => {
          if(shop.impression) {
            window.dataLayer.push({
              event: 'productClick',
              ecommerce: {
                currencyCode: 'USD',
                click: {
                  actionField: {
                    list: eCommerceListName,
                  },
                  products: [shop.impression],
                },
              },
            })
          }
        }

        return (
          <div
            className={styles.itemWrapper}
            key={shop.url}
            onClick={handleClick}
            suppressHydrationWarning
          >
            <a
              className={styles.card}
              href={shop.url}
              target={target}
            >
              <div className={styles.imgWrapper}>
                <img
                  className={styles.img}
                  alt={shop.shopTitle + 'Picture'}
                  src={shop.backgrounds['260x150']}
                  srcSet={`${shop.backgrounds['520x300']} 2x, ${shop.backgrounds['260x150']} 1x`}
                  loading='lazy'
                />
              </div>

              <div className={styles.info}>
                <strong className={styles.shopTitle}>{shop.shopTitle}</strong>
                <ul className={styles.features}>
                  <li className={styles.featureItem}>
                    <i className={cx(styles.icon, 'font-icons location-icon')} />
                    {shop.areaTitle}, {shop.countryTitle}
                  </li>
                  <li className={styles.featureItem}>
                    <i className={cx(styles.icon, 'font-icons dive-center-icon')} />
                    {shop.numberOfDivesMax
                      ? (
                      <FormattedMessage
                        id='including_dives_min_max'
                        defaultMessage='Including { divesMin } - { divesMax } dives'
                        values={{
                          divesMin: shop.numberOfDivesMin,
                          divesMax: shop.numberOfDivesMax,
                        }}
                      />
                        )
                      : (
                      <FormattedMessage
                        id='including_dives_min'
                        defaultMessage='Including { divesMin } dives'
                        values={{
                          divesMin: shop.numberOfDivesMin,
                        }}
                      />
                        )}
                  </li>
                  <li className={styles.featureItem}>
                    <i className={cx(styles.icon, 'font-icons calendar-icon')} />
                    <TwoDatesFormat
                      dateFrom={shop.dateFrom}
                      dateTo={shop.dateTo}
                    />
                  </li>
                </ul>
              </div>
              {shop.availableSpots
                ? (
                <div className={styles.priceWrap}>
                  <PromotionLabel
                    promotion={shop.promotion}
                    currency={shop.currency}
                    price={shop.price}
                    compareAtPrice={shop.compareAtPrice}
                    showComparePrice
                  />
                  <span className={styles.price}>
                    <PriceConverter
                      amount={shop.price}
                      currency={shop.currency}
                    />
                  </span>
                  <div className={styles.priceDetails}>
                    <span className={styles.priceType}>
                      <FormattedMessage
                        id='per_person_for_nights'
                        values={{
                          duration: shop.duration,
                        }}
                        defaultMessage='per person for { duration } nights'
                      />
                    </span>
                    {shop.availableSpots < 5 && (<span className={styles.spotsLeft}>
                        <FormattedMessage
                          id='spots_left_only'
                          values={{
                            qty: shop.availableSpots,
                          }}
                          defaultMessage='Only { qty } left'
                        />
                      </span>)}
                  </div>
                </div>
                  )
                : (
                <div className={styles.priceWrap}>
                  <div className={styles.soldOut}>
                    <i className={cx(styles.icon, 'font-icons fail-icon-new')} />{' '}
                    <FormattedMessage id='sold-out' defaultMessage='Sold out' />
                  </div>
                </div>
                  )}
            </a>
          </div>
        )
      })}
    </Slider>
  )
}
