import styles from './AdventureCardPlaceholder.module.scss'
export default function AdventureCardPlaceholder() {
  return (
    <div className={styles.placeholder}>
      <div className={styles.imgWrapper} />

      <div className={styles.info}>
        <strong className={styles.title} />
        <p className={styles.dcTitle} />
        <p className={styles.inclusion} />
        <p className={styles.availability} />
        <div className={styles.price}>
          <span className='old-pricing' />
          <span className='pricing' />
        </div>
      </div>
    </div>
  )
}
