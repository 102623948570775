import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import styles from './TravelSearch.module.scss'

type SearchNoResultsProps = {
  isDiveGuides: boolean
  isConservation: boolean
  worldUrl: string
}

const getMessage = ({ isDiveGuides, isConservation, worldUrl }: SearchNoResultsProps) => {
  if(isDiveGuides) {
    return (
      <FormattedMessage
        id='broadening_area'
        defaultMessage='Try broadening your search area'
      />
    )
  } else if(isConservation) {
    return (
      <FormattedMessage
        values={{
          link: chunks => <a href={worldUrl}>{ chunks }</a>,
        }}
        defaultMessage='Try broadening your search area or <link>see all conservation activites</link> all over the world'
      />
    )
  }

  return (
    <FormattedMessage
      id='sorry_not_recognize'
      defaultMessage='Sorry, we don’t recognize that name'
    />
  )
}

export const SearchNoResults = ({ isDiveGuides, isConservation, worldUrl }) => {
  return (
    <div className={cx(styles.location, styles.noResults)}>
      <i className={cx(styles.findIcon, 'font-icons find-icon')} />
      {getMessage({ isDiveGuides, isConservation, worldUrl })}
    </div>
  )
}
