import SpecialDeals from './SpecialDeals'
import homepageStyles from '../HomePage.module.scss'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import { createUrl } from '@/helpers/routes'
import { IBestDeals } from '../../../interfaces/BestDeals'

export default function HomePageDeals({ deals }: { deals: IBestDeals }) {
  return (<div className='blue-bg'>
    <section className={homepageStyles.section}>
      <h2 className={homepageStyles.sectionTitle}>
        <FormattedMessage id='padi_exclusive_deals' defaultMessage='PADI Travel exclusive deals' />
      </h2>
      <p className={homepageStyles.sectionDescription}>
        <FormattedMessage id='amazing_deals' defaultMessage='Just some of our amazing deals, from The Trusted Name in Dive Travel' /><sup> &#174;</sup>
      </p>
      <div className={homepageStyles.sliderWrapper}>
        <SpecialDeals deals={deals} eCommerceListName='Travel Homepage - Vacations - Exclusive Deals' />
      </div>
      <div>
        <a className={homepageStyles.link} href={createUrl('la_deals')}>
          <FormattedMessage id='see_all_deals' defaultMessage='See all deals' />
          <i className={cx(homepageStyles.rightArrowIcon, 'font-icons right-arrow-icon')} />
        </a>
        <a className={cx(homepageStyles.link, 'plm')} href={createUrl('last_minute_deals')}>
          <FormattedMessage id='last_minute_deals' defaultMessage='Last minute deals' />
          <i className={cx(homepageStyles.rightArrowIcon, 'font-icons right-arrow-icon')} />
        </a>
      </div>
    </section>
  </div>)
}
