/* eslint-disable react/display-name */
/* eslint-disable no-undef */

import {
  useState,
  useEffect,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react'
import API from '@/helpers/api'
import { useIntl, defineMessages } from 'react-intl'
import { makeCounter, getOffset } from '@/helpers/utils'
import SearchLocation from './SearchLocation'
import { SearchNoResults } from './SearchNoResults'
import SearchShopItem from './SearchShopItem'
import styles from './TravelSearch.module.scss'

import cx from 'classnames'
import { useDispatch } from 'react-redux'
import { setHeroPopupVisibility } from '@/actions/main-actions'
import {
  AutosuggestData,
  ConservationAutosuggestData,
  DiveGuideAutosuggestData,
  Fleet,
  Gplace,
  Place,
  Shop,
} from './interfaces'
import LoadingIndicator from '@/helpers/LoadingIndicator'

const messages = defineMessages({
  homepageSearchPlaceholder: {
    id: 'homepage.search.placeholder',
    defaultMessage: 'Where do you want to go?',
  },
  homepageSearchPlaceholderConservation: {
    id: 'homepage.search.placeholder.conservation',
    defaultMessage: 'Where do you want to protect?',
  },
})

const toggleFullScreen = (popup: HTMLDivElement, onDestinationPopupToggle: (isVisible: boolean) => void) => {
  if(!popup) {
    return false
  }
  if(window.innerWidth < 768) {
    window.scrollTo(0, 0)
    document.querySelector('body').classList.add('opened-popup')
    popup.style.top = '70px'
    popup.style.position = 'fixed'
    popup.style.bottom = '0'
    onDestinationPopupToggle(true)
  }
}

const showPopup = (popup: HTMLDivElement, inputRef: HTMLDivElement, onDestinationPopupToggle: (isVisible: boolean) => void) => {
  if(!popup) {
    return false
  }

  const inputOffset = getOffset(inputRef)
  popup.style.opacity = '1'

  popup.style.left = `${(window.innerWidth - popup.offsetWidth) / 2}px`
  popup.style.top = `${inputOffset.top - 35}px`
  popup.style.bottom = ''

  toggleFullScreen(popup, onDestinationPopupToggle)
}

const hidePopup = (popup: HTMLDivElement, onDestinationPopupToggle?: (isVisible: boolean) => void) => {
  if(!popup) {
    return false
  }
  popup.style.opacity = '0'
  if(typeof onDestinationPopupToggle === 'function') {
    document.querySelector('body').classList.remove('opened-popup')
    onDestinationPopupToggle(false)
  }
  setTimeout(() => {
    popup.style.left = '-9999px'
  }, 300)
}

type TravelSearchProps = {
  icon?: string
  popupId?: string
  activateHeaderSearch?: (value: boolean) => void
  setRedirectUrl?: (url: string) => void
  onDestinationPopupToggle?: (isVisible: boolean) => void
  isDiveGuides?: boolean
  isConservation?: boolean
  worldUrl?: string
}
export type TravelSearchRef = {
  showResults: () => void
}

const TravelSearch = forwardRef<TravelSearchRef, TravelSearchProps>((props, ref) => {
  const {
    icon,
    popupId,
    activateHeaderSearch,
    setRedirectUrl,
    onDestinationPopupToggle,
    isDiveGuides,
    isConservation,
    worldUrl,
  } = props

  const intl = useIntl()
  const dispatch = useDispatch()

  const [searchString, setSearchString] = useState<string>('')
  const [locations, setLocations] = useState<(Gplace | Place | ConservationAutosuggestData | DiveGuideAutosuggestData | Fleet)[]>([])
  const [shops, setShops] = useState<Shop[]>([])
  const [isLoading, setLoading] = useState(false)
  const [isVisible, setVisible] = useState(false)
  const [searchNoResults, setSearchNoResults] = useState(false)
  const [isInitialActivation, setIsInitialActivation] = useState(true)
  const container = useRef()
  const inputRef = useRef()
  const counter = makeCounter()
  const API_URL = useMemo(() => {
    if(isConservation) {
      return 'search/autosuggest/sto/'
    } else if(isDiveGuides) {
      return 'dive-guide/autosuggest/'
    }

    return 'search/autosuggest/homepage/'
  }, [])

  useImperativeHandle(ref, () => ({
    showResults() {
      if(!searchString) {
        setSearchNoResults(true)
        setIsInitialActivation(false)
        onFocus()
      }
    },
  }))

  const popup = useMemo(() => {
    if(typeof document !== 'undefined') {
      return document.getElementById(popupId) as HTMLDivElement
    }
  }, [popupId])

  const onMobileCloseBtnClick = () => {
    setVisible(false)
    hidePopup(popup, onDestinationPopupToggle)
  }

  const handleChange = ({ target }) => {
    const text = target.value
    setSearchNoResults(false)
    setSearchString(text)
    if(text.length > 2) {
      setLoading(true)
      API(API_URL)
        .get({
          q: text,
        })
        .then((data: AutosuggestData | DiveGuideAutosuggestData[] | ConservationAutosuggestData[]) => {
          const locations =
            isDiveGuides || isConservation
              ? data as DiveGuideAutosuggestData[] | ConservationAutosuggestData[]
              : [...(data as AutosuggestData).gplaces, ...(data as AutosuggestData).places, ...(data as AutosuggestData).fleets]

          setLocations(locations)

          const shops = (data as AutosuggestData).shops
          if(shops?.length) {
            const items:Shop[] = []

            shops.forEach((shop) => {
              items.push(shop)
              if(shop.kind === 20 && shop.diveCenterUrl) {
                items.push({
                  ...shop,
                  travelUrl: shop.diveCenterUrl,
                  title: shop.diveCenterTitle,
                  kind: 0,
                })
              }
            })
            setShops(items)
          }
          setIsInitialActivation(false)

          let url = ''

          if(locations.length) {
            url = isDiveGuides
              ? (locations[0] as DiveGuideAutosuggestData).diveSearchUrl
              : (locations[0] as Place).liveaboardSearchUrl
          }
          setRedirectUrl(url)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLocations([])
      setShops([])
    }
  }
  const onFocus = () => {
    setVisible(true)
    if(activateHeaderSearch) {
      activateHeaderSearch(true)
    }
  }

  const onBlur = () => {
    if(window.innerWidth >= 768) {
      setSearchNoResults(false)
      setVisible(false)
      hidePopup(popup, onDestinationPopupToggle)
    }
  }

  const triggerPopup = () => {
    if(!popup) {
      return false
    }
    if(searchString.length < 3) {
      dispatch(setHeroPopupVisibility(true))
      showPopup(popup, inputRef.current, onDestinationPopupToggle)
    } else {
      hidePopup(popup)
    }
  }

  const onArrowPress = (type) => {
    const currentContainer = container.current as HTMLDivElement
    const rows = currentContainer?.querySelectorAll('.search-row') as NodeListOf<HTMLDivElement>

    if(type === 'down') {
      if(counter.value() < rows.length) {
        counter.increment()
      } else {
        counter.setValue(1)
      }
    } else {
      if(counter.value() > 1) {
        counter.decrement()
      } else {
        counter.setValue(rows.length)
      }
    }
    rows.forEach((row, index) => {
      row.classList.remove(styles.active)
      if(index + 1 === counter.value()) {
        row.classList.add(styles.active)
        row.focus()
        currentContainer
          .querySelector('.search-container')
          .scrollTo(0, row.offsetTop)
      }
    })
  }

  const onKeyPress = ({ key }) => {
    if(key === 'ArrowDown') {
      onArrowPress('down')
    } else if(key === 'ArrowUp') {
      onArrowPress('up')
    } else if(key === 'Enter') {
      const currentContainer = container.current as HTMLDivElement
      const activeElement =
        currentContainer.querySelector(`.search-row.${styles.active}`) as HTMLDivElement ||
        currentContainer.querySelector('.search-row') as HTMLDivElement

      if(activeElement) {
        activeElement.click()
      }
    }
  }

  useEffect(() => {
    if(!isVisible) {
      hidePopup(popup)
    } else {
      triggerPopup()
      toggleFullScreen(popup, onDestinationPopupToggle)
    }
  }, [searchString, isVisible])

  useEffect(() => {
    (container.current as HTMLDivElement).addEventListener('keydown', onKeyPress)
  }, [])

  return (
    <div
      className={styles.container}
      onFocus={onFocus}
      onBlur={onBlur}
      ref={container}
      tabIndex={0}
    >
      <div className={styles.searchWrapper}>
        <i className={cx(styles.locationIcon, 'font-icons', icon || 'location-icon')}/>
        <input
          data-testid='search-input'
          value={searchString}
          onChange={handleChange}
          ref={inputRef}
          className={styles.searchInput}
          placeholder={intl.formatMessage(
            isConservation
              ? messages.homepageSearchPlaceholderConservation
              : messages.homepageSearchPlaceholder,
          )}
        />
        <span
          className={cx(styles.closeBtn, 'close-icon font-icons close-icon-search')}
          onClick={onMobileCloseBtnClick}
        />
        {isLoading && <LoadingIndicator isMobile={window?.innerWidth <= 700} />}
      </div>
      {!locations.length && !shops.length && !isInitialActivation && (
        <div
          className={cx(styles.results, { [styles.notVisible]: (!isVisible || searchString.length < 3) && !searchNoResults }, 'search-container')}
        >
          <SearchNoResults
            isDiveGuides={isDiveGuides}
            isConservation={isConservation}
            worldUrl={worldUrl}
          />
        </div>
      )}
      {!!(locations.length || shops.length) && (
        <div className={cx(styles.results, 'search-container', { [styles.notVisible]: !isVisible })}>
          {locations.map((location, index) => (
            <SearchLocation
              key={`${location.title}${(location as Place).formattedAddress}${index}`}
              location={location}
              isFirstRow={index === 0}
              isDiveGuides={isDiveGuides}
              isConservation={isConservation}
            />
          ))}
          {shops.map((shop) => (
            <SearchShopItem shop={shop} key={shop.pk + shop.title} isDiveGuides={isDiveGuides} isConservation={isConservation} />
          ))}
        </div>
      )}
    </div>
  )
})

export default TravelSearch
