import styles from './WishlistButton.module.scss'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Fragment, useEffect, useState } from 'react'

import Api from '@/helpers/api'
import { getOffset, isMobile } from '@/helpers/utils'
import { setPopupHidden } from '@/actions/main-actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'

const isDeviceMobile = isMobile()

interface WishlistButtonProps {
  isInWishlistInit: boolean
  wishlisthLeftAlign?: boolean
  id: number
  shopSlug?: string
  type?: string
  isSingleShop?: boolean
}

export default function WishlistButton({
  isInWishlistInit,
  wishlisthLeftAlign,
  id,
  shopSlug,
  type = 'Shop',
  isSingleShop,
}: WishlistButtonProps) {
  const [isInWishlist, setIsInWishlist] = useState<boolean>(isInWishlistInit)
  const [wishButtonState, setWishButtonState] = useState<string>(isInWishlistInit ? 'delete' : 'add')
  const [elemOffset, setElemOffset] = useState<Record<string, number>>({})
  const [elemWidth, setElemWidth] = useState<number>()
  const dispatch = useDispatch()
  const isPopupHidden = useSelector((state: RootState) => state.isPopupHidden)
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn)

  const updateState = () => {
    setWishButtonState((prevState) => prevState === 'add' ? 'delete' : 'add')
    setIsInWishlist(wishButtonState !== 'delete')
  }

  useEffect(() => {
    const popup = document.getElementById('wishlist-popup') as HTMLDivElement
    const wishlistPopupWidth = popup.offsetWidth
    const parentPosition = elemOffset.left
    const topOffset =
          elemOffset.top + popup.clientHeight >=
          window.innerHeight + window.scrollY
            ? elemOffset.top - popup.offsetHeight / 2
            : elemOffset.top

    if(parentPosition - wishlistPopupWidth < 0) {
      popup.style.left = isDeviceMobile
        ? '0px'
        : elemOffset.left + elemWidth - wishlistPopupWidth + 'px'
      popup.style.top = topOffset - popup.clientHeight + 50 + 'px'
    } else {
      popup.style.left = elemOffset.left - wishlistPopupWidth - 20 + 'px'
      popup.style.top = topOffset - popup.clientHeight / 2 + 'px'
    }
  }, [isPopupHidden])

  const triggerWishlist = (event) => {
    if(!isLoggedIn) {
      dispatch(setPopupHidden(false))
      setElemOffset(getOffset(event.target))
      setElemWidth(event.target.offsetWidth)
      return
    }

    const data = {
      item_type: type,
      item_slug: shopSlug,
    }
    if(isInWishlist) {
      setWishButtonState('delete')
    }

    if(type === 'Activity') {
      Api(`/api/adventure/v1/wishlists/default/activities/${wishButtonState}/`)
        .post({
          activityId: id,
        })
        .then(() => {
          updateState()
        })
    } else if(wishButtonState === 'add') {
      Api(`account/wishlist/${wishButtonState}/`)
        .post(data)
        .then(() => {
          updateState()
        })
    } else {
      Api(`account/wishlist/${wishButtonState}/${data}/`)
        .delete(data)
        .then(() => {
          updateState()
        },
        )
    }
  }

  return (
    <Fragment>
    <span
      className={cx(styles.heart, {
        [styles.leftAlign]: wishlisthLeftAlign,
        [styles.active]: isInWishlist,
      }, 'font-icons heart-icon')}
      onClick={triggerWishlist}
    >
      {isSingleShop &&
        (<Fragment>
          <i className="font-icons heart-icon" onClick={triggerWishlist}></i>
          {wishButtonState === 'add'
            ? <FormattedMessage id='addToWishlist' defaultMessage='ADD TO WISHLIST'/>
            : <FormattedMessage id='removeFromWishlist' defaultMessage='REMOVE FROM WISHLIST'/>
          }

        </Fragment>)
      }

    </span>
    </Fragment>
  )
}
