/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Fragment, useMemo } from 'react'
import { useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'
import store from 'redux/store'
import { RootState } from '@/store'

export function convertCurrency(
  amount: string | number,
  currency: string = 'USD',
  to?: string,
  selectedCurrency?: string,
  conversionPercent?: boolean,
) {
  return convert(amount, currency, to || selectedCurrency, conversionPercent)
}

function convert(
  amount: string | number,
  currency: string = 'USD',
  currencyToConvert: string,
  conversionPercent: boolean) {
  let convertedAmount
  const { currencyInfo } = store.getState()
  if(currency === currencyToConvert || !currencyInfo.rates) {
    return amount
  }
  if(currency !== currencyInfo.baseCurrency) {
    convertedAmount = (Number(amount) / currencyInfo.rates[currency]) * currencyInfo.rates[currencyToConvert]
  } else {
    convertedAmount = Number(amount) * currencyInfo.rates[currencyToConvert]
  }

  if(conversionPercent) {
    return convertedAmount * (1 + 3 / 100)
  }

  if(currencyInfo.discountPercentage) {
    return convertedAmount * (1 - currencyInfo.discountPercentage)
  }

  return convertedAmount
}

interface IPriceConverter {
  amount: string | number
  currency?: string
  onlyNumber?: boolean
  to?: string
  decimals?: boolean
  noCurrency?: boolean
  conversionPercent?: boolean
}

export default function PriceConverter({
  amount,
  currency = 'USD',
  onlyNumber,
  to,
  decimals,
  noCurrency,
  conversionPercent,
}: IPriceConverter) {
  const { currencyInfo, selectedCurrency } = useSelector((state: RootState) => ({
    currencyInfo: state.currencyInfo,
    selectedCurrency: state.selectedCurrency,
  }))
  const convertedCurrency = useMemo(() => {
    const toCurrency = to || selectedCurrency
    const value = convert(amount, currency, toCurrency, conversionPercent)
    if(onlyNumber) {
      return value
    }

    if(value >= 100000 && value < 1000000) {
      return (
        <NumberFormat
          suppressHydrationWarning
          thousandSeparator
          displayType='text'
          prefix={noCurrency ? '' : toCurrency + ' '}
          suffix='K'
          decimalScale={1}
          value={value / 1000}
        />
      )
    } else if(value >= 1000000 && value < 1000000000) {
      return (
        <NumberFormat
          suppressHydrationWarning
          thousandSeparator
          displayType='text'
          prefix={noCurrency ? '' : toCurrency + ' '}
          suffix='M'
          decimalScale={2}
          value={value / 1000000}
        />
      )
    } else if(value >= 1000000000) {
      return (
        <NumberFormat
          suppressHydrationWarning
          thousandSeparator
          displayType='text'
          prefix={noCurrency ? '' : toCurrency + ' '}
          suffix='Bn'
          decimalScale={2}
          value={value / 1000000000}
        />
      )
    }
    return (
      <NumberFormat
        suppressHydrationWarning
        thousandSeparator
        displayType='text'
        prefix={noCurrency ? '' : toCurrency + ' '}
        decimalScale={decimals ? 2 : 0}
        fixedDecimalScale={decimals}
        value={value}
      />
    )
  }, [amount, to, currency, selectedCurrency, currencyInfo])
  return (
    <Fragment >
      {amount
        ? convertedCurrency
        : `${selectedCurrency} 0`}
    </Fragment>
  )
}
