import useUser from '../../../hooks/useUser'
import { defineMessage, FormattedMessage, useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'

import styles from './AffiliatesBar.module.scss'
import cx from 'classnames'
import get from 'lodash/get'
import { copyTextToClipboard, generateAffiliateUrl } from '@/helpers/utils'

const affiliatesDomain = process.env.NEXT_PUBLIC_AFFILIATES_DOMAIN

export default function AffiliateBar() {
  const { user, updateUser } = useUser()
  const { formatMessage } = useIntl()

  if(!user || !user.isShopManager || !user.affiliates.length) { return null }

  const longUrl = generateAffiliateUrl(user)
  const { affiliate, affiliates } = user

  const memberNumber = get(affiliate, 'memberNumber')

  const changeAffiliatesHandle = (memberNumber) => {
    const selectedAffiliate = affiliates.find((el) => el.memberNumber === memberNumber)

    if(selectedAffiliate) {
      updateUser({ affiliate: selectedAffiliate })
    }
  }

  const copyLinkHandler = () => {
    copyTextToClipboard(longUrl, formatMessage(defineMessage({ defaultMessage: 'Link copied to clipboard' })))
  }

  return (<div className={styles.bar}>
      <div className={styles.column}>
        <ReactTooltip place='bottom' effect='solid' id='info'/>
        <i data-for='info'
           data-tip={formatMessage(
             { defaultMessage: `You're logged in as an affiliate, you're eligible to earn commissions for every booking you make while logged in.` })}
           className={cx('font-icons info-icon', styles.infoIcon)}
        />
        <span>
          <FormattedMessage defaultMessage='AFFILIATE (ID: {memberNumber})' id='affiliate_id' values={{ memberNumber }} />
            <i className={cx('font-icons bottom-arrow-icon', styles.bottomArrowIcon)}></i>
            <div className={styles.shareBlock}>
              {affiliates.map((el) => (
                  <a key={el.id} onClick={() => changeAffiliatesHandle(el.memberNumber)}>{el?.businessInfo?.name}</a>))}
            </div>
      </span>
      </div>
      <div className={styles.column}>
        <a href={`${affiliatesDomain}/dashboard/?affiliate_id=${memberNumber}`}>
          <FormattedMessage
              defaultMessage='Go To Dashboard'
              id='go_to_dashboard'
          />
        </a>
        <a
            href={`${affiliatesDomain}/group-trips/?group_booking_modal=true&affiliate_id=${memberNumber}`}
        >
          <FormattedMessage
              defaultMessage='Get Group Trip Quote'
              id='get_group_trip_quote'
          />
        </a>
        <a
            href={`${affiliatesDomain}/group-trips/?lead_modal=true&affiliate_id=${memberNumber}`}
        >
          <FormattedMessage defaultMessage='Send Lead' id='send_lead' />
        </a>
      </div>
      <div className={styles.column}>
        {affiliates?.[0]?.settingInfo?.isTermsAccepted &&
        <span onClick={copyLinkHandler}>
          <i className='font-icons icon-link'></i>
          <FormattedMessage defaultMessage='Link to this page'/>
        </span>
        }
        {affiliates?.[0]?.settingInfo?.isTermsAccepted &&
            <span>
            <i className='font-icons icon-share'/>
            <FormattedMessage defaultMessage=' Share this page' id='share_this_page'/>
            <div className={styles.shareBlock}>
              <a target='_blank'
                 href={`https://www.facebook.com/sharer.php?u=${longUrl}`} rel="noreferrer"
              ><i className='font-icons fb-icon'/>Facebook</a>
              <a target='_blank'
                 href={`https://twitter.com/intent/tweet?url=${longUrl}`} rel="noreferrer"
              ><i className='font-icons twitter-icon'/>Twitter</a>
              <a target='_blank'
                 href={`https://www.pinterest.com/pin/create/button/?url=${longUrl}&media=https%3A//s.diviac.com/travel_dist/images/padi_travel_social_default.jpg&description=`}
                 rel="noreferrer"
              ><i className='font-icons icon-pinterest'/>Pinterest</a>
              <a target='_blank'
                 href={`https://www.linkedin.com/shareArticle?mini=true&url=${longUrl}&title=PADI%20Travel&summary=PADI%20Travel&source=`}
                 rel="noreferrer"
              ><i className='font-icons icon-linkedin'/>Linkedin</a>
            </div>
          </span>
        }
      </div>
    </div>)
}
