import Slider, { LazyLoadTypes } from 'react-slick'
import styles from './TravelThemes.module.scss'
import homepageStyles from '../HomePage.module.scss'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import { createUrl } from '@/helpers/routes'
import { Result } from 'interfaces/Collections'

const settings = {
  slidesToShow: 6,
  slidesToScroll: 1,
  infinite: false,
  lazyLoad: 'progressive' as LazyLoadTypes, 
  dots: true,
  responsive: [
    { breakpoint: 1025, settings: { slidesToShow: 4, slidesToScroll: 1 } },
    {
      breakpoint: 769,
      settings: { slidesToShow: 3, slidesToScroll: 1, arrows: false },
    },
    {
      breakpoint: 481,
      settings: { slidesToShow: 2.3, slidesToScroll: 1, arrows: false },
    },
  ],
}

export default function TravelThemes({ collections }: { collections: Result[] }) {
  return (<div className='blue-bg'>
    <section className={cx(homepageStyles.section, homepageStyles.sectionMargin)}>
      <h2 className={homepageStyles.sectionTitle}>
        <FormattedMessage id='collections.not_sure' defaultMessage='Not sure where to go?' />
      </h2>
      <p className={homepageStyles.sectionDescription}>
        <FormattedMessage id='collections.description' defaultMessage='Get some inspiration from our travel themes.' />
      </p>
      <div className={styles.collections}>
        <Slider className='slick-pt-custom same-height space-between-small' {...settings}>
          {collections.map((collection) => {
            return (
              <a
                className={styles.item}
                key={collection.url}
                href={collection.url}
                title={`Details: ${collection.title}`}
              >
                <img
                  className={styles.image}
                  src={collection.mainImage['400x520']}
                  srcSet={`${collection.mainImage['400x520']} 2x, ${collection.mainImage['200x260']} 1x`}
                  loading='lazy'
                  alt={`Picture: ${collection.title}`}
                />
                <p className={styles.title}>
                  {collection.title}
                </p>
                <span className={styles.overlay} />
              </a>
            )
          })}
        </Slider>
      </div>
      <a href={createUrl('collections')} className={homepageStyles.link}>
        <FormattedMessage id='see_all_themes' defaultMessage='See all travel themes' />
        <i className={cx(homepageStyles.rightArrowIcon, 'font-icons right-arrow-icon')} />
      </a>
    </section>
  </div>
  )
}
