import { useMemo } from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'
import { IAdventureItem } from '../../interfaces/Adventures'

const messages = defineMessages({
  numberOfDives: {
    id: 'number_of_dives_activity_inclusions',
    defaultMessage: '{numberOfDives, plural, one {# dive} other {# dives}}',
  },
  courseInclusionDays: {
    id: 'course_days',
    defaultMessage:
      '{numberOfDays, plural, one {# day} other {# days}}, incl. {numberOfDives, plural, one {# dive} other {# dives}}',
  },
  courseInclusionHours: {
    id: 'course_hours',
    defaultMessage:
      '{numberOfHours, plural, one {# hour} other {# hours}}, incl. {numberOfDives, plural, one {# dive} other {# dives}}',
  },
})

type ActivityInclusionsProps = {
  item: IAdventureItem
}

export default function ActivityInclusions({ item }: ActivityInclusionsProps) {
  const message = useMemo(() => {
    if(
      item.activityType === 'dive_trip' ||
      item.activityType === 'social_event'
    ) {
      return (
        <FormattedMessage
          {...messages.numberOfDives}
          values={{
            numberOfDives: item.numberOfDives,
          }}
        />
      )
    }
    if(item.activityType === 'course') {
      const hours = item.durationHours
      if(hours >= 24) {
        return (
          <FormattedMessage
            {...messages.courseInclusionDays}
            values={{
              numberOfDays: hours / 24,
              numberOfDives: item.numberOfDives,
            }}
          />
        )
      }
      return (
        <FormattedMessage
          {...messages.courseInclusionHours}
          values={{
            numberOfHours: hours,
            numberOfDives: item.numberOfDives,
          }}
        />
      )
    }
    return null
  }, [item.activityType])
  if(!message) {
    return null
  }
  return message
}
