import { setUser } from '@/actions/main-actions'
import { User, Affiliate } from 'interfaces/User'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'

type UserData = {
  isStaff?: boolean
  isProDiver?: boolean
  affiliates?: Affiliate[]
  user?: User
  updateUser?: (data: Partial<User>) => void
}

export default function useUser():UserData {
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => {
    return state.currentUser as User
  })

  const updateUser = (data: Partial<User>) => {
    dispatch(
      setUser({
        ...user,
        ...data,
      }),
    )
  }

  return {
    isStaff: user?.isStaff,
    isProDiver: user?.isProDiver,
    affiliates: user?.affiliates,
    user,
    updateUser,
  }
}
