import wishlistStyles from '@/helpers/WishlistButton.module.scss'
import cx from 'classnames'
import { createUrl } from '@/helpers/routes'
import { target } from '@/helpers/utils'
import { useDispatch, useSelector } from 'react-redux'
import { setPopupHidden } from '@/actions/main-actions'
import { FormattedMessage } from 'react-intl'
import { RootState } from 'redux/store'

const WishlistPopup = () => {
  const dispatch = useDispatch()
  const isHidden = useSelector((state: RootState) => state.isPopupHidden)
  const hidePopup = () => {
    dispatch(setPopupHidden(true))
  }
  return (
    <div className={cx(wishlistStyles.wishlistMessage, 'fade-onload', { [wishlistStyles.hidden]: isHidden })} id='wishlist-popup'>
      <span className={wishlistStyles.close} id="close-wishlist" onClick={hidePopup}>x</span>
      <p className={wishlistStyles.title}>
        <FormattedMessage id='wishlistpopup_header' defaultMessage='Save that favourite'/>

      </p>
      <p>
        <FormattedMessage id='wishlistpopup_register_message' defaultMessage='With a PADI Travel account, you can favourite dive operators to come back to later on any device or computer'/>
        </p>
      <a className={wishlistStyles.loginLink} href={createUrl('login')} target={target}>
        <FormattedMessage id='wishlistpopup_login_sign_up' defaultMessage='Log in or sign up' />
      </a>
    </div>
  )
}

export default WishlistPopup
