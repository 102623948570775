import { useRef, useState } from 'react'
import TravelSearch from './../search-autosuggest/TravelSearch'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import styles from './HeroSearch.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { createUrl } from '@/helpers/routes'
import { showLoader } from '@/actions/main-actions'
import { RootState } from 'redux/store'
import { TravelSearchRef } from '../search-autosuggest/TravelSearch'

interface IHeroSearchProps {
  allowRedirect?: boolean
  isDiveGuides?: boolean
  isConservation?: boolean
  worldUrl?: string
}

export default function HeroSearch({
  allowRedirect,
  isDiveGuides,
  isConservation,
  worldUrl,
}: IHeroSearchProps) {
  const dispatch = useDispatch()
  const pageLoader = useSelector((state: RootState) => state.pageLoader)
  const searchInputRef = useRef<TravelSearchRef>()
  const [activeMobileDestinations, setMobileDestinationsVisible] = useState<boolean>(
    false,
  )

  const [selectedUrl, setSelectedUrl] = useState<string>('')

  const onLocationFound = (url: string) => {
    setSelectedUrl(url)
  }
  const handleSubmit = () => {
    if(!selectedUrl && (isDiveGuides || isConservation)) {
      // on dive guides pages we show no results without redirecting
      if(searchInputRef.current) {
        searchInputRef.current.showResults()
      }
      return
    }

    if(!pageLoader) {
      // if the location was found - we redirect on click to this location
      if(allowRedirect || selectedUrl) {
        dispatch(showLoader(true))
        window.location.href = selectedUrl || createUrl('search_la', { slug: 'all' })
      }
    }
  }

  const toggleMobileDestinations = (close: boolean) => {
    setMobileDestinationsVisible(close)
  }

  return (
      <div
        data-testid='hero-container'
        className={cx(styles.container, {
          'placeholder-animation': pageLoader,
          [styles.mobileSearch]: activeMobileDestinations,
        })}
      >
        <div className='hero-search__field'>
          <TravelSearch
            popupId='liveaboards-popup'
            ref={searchInputRef}
            isDiveGuides={isDiveGuides}
            isConservation={isConservation}
            onDestinationPopupToggle={toggleMobileDestinations}
            setRedirectUrl={onLocationFound}
            worldUrl={worldUrl}
          />
        </div>
        <button
          className={cx(styles.button, 'btn-red')}
          onClick={handleSubmit}
          disabled={pageLoader}
        >
          <FormattedMessage id='search' defaultMessage='Search' />
        </button>
      </div>
  )
}
