import { useDispatch } from 'react-redux'
import { showLoader } from '@/actions/main-actions'
import { trackClick } from '@/helpers/gtm'
import cx from 'classnames'
import styles from './TravelSearch.module.scss'
import { FormattedMessage } from 'react-intl'
import { Shop } from './interfaces'

const getIcon = (kind: number) => {
  switch (kind) {
    case 0:
      return 'icon-dive-center'
    case 10:
      return 'liveaboard-icon'
    case 20:
      return 'dive-resort-icon'
  }
}

export function getVerboseKind(kind: number) {
  switch (kind) {
    case 0:
      return <FormattedMessage id='dive_center' defaultMessage='Dive center' />
    case 10:
      return <FormattedMessage id='liveaboard' defaultMessage='Liveaboard' />
    case 20:
      return <FormattedMessage id='dive_resort' defaultMessage='Dive resort' />
  }
}

type SearchShopItemProps = {
  shop: Shop
  isDiveGuides: boolean
  isConservation: boolean
}

const SearchShopItem = ({ shop, isDiveGuides, isConservation }: SearchShopItemProps) => {
  const dispatch = useDispatch()
  const handleLocationClick = () => {
    (document.activeElement as HTMLElement).blur()
    dispatch(showLoader(true))

    if(isDiveGuides || isConservation) {
      trackClick({
        regionIdentifier: 'search-auto-complete',
        link: shop.travelUrl,
      })
    }

    window.location.href = shop.travelUrl
  }
  return (<div className={styles.row}>
        <div
          className={cx(styles.location, 'search-row')}
          onClick={handleLocationClick}
        >
          <i className={cx(styles.shopIcon, styles[getIcon(shop.kind)], `font-icons shop-icon ${getIcon(shop.kind)}`)} />
          <div className={styles.locationWrapper}>
            <span className={styles.locationTitle}>
              {shop.title}
            </span>
            <span className={styles.locationAddress}>
              {getVerboseKind(shop.kind)}
            </span>
          </div>
        </div>
      </div>
  )
}

export default SearchShopItem
