import SlickSlider, { LazyLoadTypes } from 'react-slick'
import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import AdventureCard from './AdventureCard'
import AdventureCardPlaceholder from './AdventureCardPlaceholder'
import { pushToDataLayer } from '@/helpers/gtm'
import get from 'lodash/get'
import { IAdventureItem } from 'interfaces'
import { RootState } from '@/store'

const placeholderItems = [...new Array(4)]

function setImpressionList(item, index) {
  return {
    list: item.actionField,
    position: index + 1,
    ...item.impression,
  }
}

type AdventuresSliderProps = {
  items: IAdventureItem[]
  isLoading?: boolean
}

export default function AdventuresSlider({ items, isLoading }:AdventuresSliderProps) {
  const selectedCurrency = useSelector((state: RootState) => state.selectedCurrency)
  const ref = useRef<SlickSlider>()
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    dots: true,
    lazyLoad: 'progressive' as LazyLoadTypes,
    responsive: [
      { breakpoint: 1025, settings: { slidesToShow: 3, slidesToScroll: 3 } },
      {
        breakpoint: 769,
        settings: { slidesToShow: 2.5, slidesToScroll: 1, arrows: false },
      },
      {
        breakpoint: 481,
        settings: { slidesToShow: 1.2, slidesToScroll: 1, arrows: false },
      },
    ],
    beforeChange: (a, b) => {
      const impression = get(items, '[0].impression')
      if(impression) {
        const difference = b + Math.abs(a - b)
        pushToDataLayer({
          event: 'impression',
          ecommerce: {
            currencyCode: selectedCurrency,
            impressions: items.slice(b, difference).map(setImpressionList),
          },
        })
      }
    },
  }

  useEffect(() => {
    const numberOfItemsToShow = get(
      ref,
      'current.innerSlider.props.slidesToShow',
    )
    const impression = get(items, '[0].impression')
    if(numberOfItemsToShow && !isLoading && impression) {
      pushToDataLayer({
        event: 'impression',
        ecommerce: {
          currencyCode: selectedCurrency,
          impressions: items
            .slice(0, numberOfItemsToShow)
            .map(setImpressionList),
        },
      })
      // scroll to first slide when user change tabs
      ref.current.slickGoTo(0)
    }
  }, [ref.current, items.length, isLoading])
  return (
    <SlickSlider
      ref={ref}
      className={`slick-pt-custom same-height space-between-small ${
        isLoading ? 'placeholder-animation' : ''
      }`}
      {...settings}
    >
      {isLoading &&
        placeholderItems.map((_, i) => {
          return <AdventureCardPlaceholder key={i} />
        })}
      {items &&
        items.map((trip) => {
          return (<AdventureCard
              adventure={trip}
              key={trip.id}
            />)
        })}
    </SlickSlider>
  )
}
