import Head from 'next/head'
import { GetStaticProps } from 'next'
import { useEffect } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { HeroSection, FeaturedDestinations, HomePageDeals, Banner, TravelThemes, AdventuresNearby, TopDestinations, SeekAdventures, Destinations, ContactUs } from '@/components/homepage/'
import API from '@/helpers/api'
import MetaTags from '@/helpers/MetaTags'
import { useDispatch } from 'react-redux'
import { setCurrency, setRates } from '@/actions/main-actions'
import { navigationClicks } from '@/helpers/gtm'
import LiveChatButton from '@/components/shared/LiveChatBtn'
import { useRouter } from 'next/router'
import Script from 'next/script'
import WishlistPopup from '@/helpers/WishlistPopup'
import AffiliateBar from '@/components/homepage/affiliate-bar/AffiliateBar'
import { ToastContainer } from 'react-toastify'
import GitBranch from '@/components/shared/GitBranch'
import { IMenuItems, ICurrencies, IMainDestinations, ICollections, IBestDeals, IFeaturedDestination, ITopDestinationPrices, IFooterData } from 'interfaces'
import dynamic from 'next/dynamic'
import { getCookie } from '@/helpers/utils'

interface IHome {
  menuItems: IMenuItems
  currencies: ICurrencies
  mainDestinations: IMainDestinations[]
  collections: ICollections
  deals: IBestDeals
  featuredDestinations: IFeaturedDestination[]
  footerDestinations: IFooterData[]
  destinationPrices: ITopDestinationPrices[]
}

const messages = defineMessages({
  title: {
    id: 'homepageTitle',
    defaultMessage: 'PADI Travel | Scuba Diving Vacations',
  },
  description: {
    id: 'homepageDescription',
    defaultMessage: '✓ Biggest Selection ✓ 24/7 Support ✓ True Online Booking ✓ Best Price Guarantee ✓ No Hidden Fees ✓ Free Dive Insurance. Book Your Dive Vacation With PADI Travel!',
  },
})

const HeaderDynamic = dynamic(() => import('@/components/header/Header'), { ssr: false })

const Footer = dynamic(() => import('@/components/footer/Footer'), {ssr: false})

const Home = ({ menuItems, currencies, mainDestinations, collections, deals, featuredDestinations, footerDestinations, destinationPrices }: IHome) => {
  const router = useRouter()
  const { intercom } = router.query
  const intl = useIntl()
  const dispatch = useDispatch()
  useEffect(() => {
    navigationClicks('/')
    dispatch(setRates(currencies))

    if(!getCookie('currency_code')) {
      dispatch(setCurrency('USD'))
    }

    if(intercom && intercom === 'true' && window.Intercom) {
      setTimeout(() => {
        window.Intercom('show')
      }, 1500)
    }
  }, [])

  return (
    <section role='main'>
      <ToastContainer />
      <Head>
        <title>{intl.formatMessage(messages.title)}</title>
        <MetaTags
          title={intl.formatMessage(messages.title)}
          description={intl.formatMessage(messages.description)}
        />
      </Head>
      <Script src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js' />
      <AffiliateBar />
      <GitBranch />
      <HeaderDynamic
        menuItems={menuItems}
        currencies={currencies}
      />
      <HeroSection popupData={mainDestinations} />
      <TopDestinations />
      <WishlistPopup />
      <AdventuresNearby />
      <TravelThemes collections={collections.results} />
      <Banner />
      <HomePageDeals deals={deals} />
      <FeaturedDestinations destinations={featuredDestinations} />
      <SeekAdventures />
      <Destinations items={destinationPrices} />
      <ContactUs />
      <LiveChatButton />
      <Footer destinations={footerDestinations} />
    </section>
  )
}

export const getStaticProps:GetStaticProps = async (context) => {
  const [menuItems, currencies, mainDestinations, collections, deals, featuredDestinations, destinationPrices, footerDestinations] = await Promise.all([
    API('menu/', context.locale).get() as Promise<IMenuItems>,
    API('booking/currency/', context.locale).get() as Promise<ICurrencies>,
    API('home/main-destinations/', context.locale).get() as Promise<IMainDestinations[]>,
    API('home/collections/', context.locale).get() as Promise<ICollections>,
    API('promotions/best-deals/', context.locale).get() as Promise<IBestDeals>,
    API('home/featured-destinations/', context.locale).get() as Promise<IFeaturedDestination[]>,
    API('home/destination-prices/', context.locale).get() as Promise<ITopDestinationPrices[]>,
    API('footer/top/', context.locale).get() as Promise<IFooterData[]>,
  ])
  return {
    props: {
      menuItems,
      currencies,
      collections,
      mainDestinations,
      deals,
      destinationPrices,
      featuredDestinations: featuredDestinations.slice(0, 5),
      footerDestinations: footerDestinations.slice(0, 5),
    },
    revalidate: 60 * 60 * 4, // 4 hours
  }
}

export default Home
