import styles from './HeroSection.module.scss'
import HeroSearch from '../hero-search/HeroSearch'
import { FormattedMessage } from 'react-intl'
import ShopTypes from '../shop-types/ShopTypes'
import cx from 'classnames'
import Picture from '@/helpers/Picture'
import { useSelector } from 'react-redux'
import { IMainDestinations } from '@/interfaces/index'
import { RootState } from '@/store'
import MegaMenu from '@/components/header/header-navigation/MegaMenu'

export default function HeroSection({ popupData }: { popupData: IMainDestinations[] }) {
  const isHeroPopupVisible = useSelector((state: RootState) => state.isHeroPopupVisible)
  return (<section className={styles.homepageHero}>
      <Picture imageStyles={styles.image} loading='eager' pictureStyles={styles.picture} wepbSrc='/homepage-main-picture.webp' src='/homepage-main-picture.jpg' alt='Homepage picture' />
      { popupData && (<div id='liveaboards-popup' className={cx(styles.laPopup, 'list-of-countires')}>
        {isHeroPopupVisible && (<MegaMenu navigationItem={{ subItems: popupData } as IMainDestinations}
          isStatic
          isActive={true}
          hint={(<FormattedMessage id='popup.hint'
            defaultMessage='Type a destination in the input field above or select one of the key diving destinations listed below.'
          />)}
        />)}
        </div>) }
      <div className={styles.searchWrapper} >
        <h1 className={styles.title}><FormattedMessage id='book_your_diving' defaultMessage='Book your diving worldwide' /></h1>
        <HeroSearch allowRedirect />
      </div>
      <ShopTypes />
  </section>)
}
