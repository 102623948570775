import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import styles from './TopDestinations.module.scss'
import homepageStyles from '../HomePage.module.scss'
import { createUrl } from '../../../helpers/routes'
import Picture from '@/helpers/Picture'
import { useRouter } from 'next/router'

const mexicoSlug = {
  en: 'mexico',
  de: 'mexiko',
  fr: 'mexique',
  it: 'messico',
  es: 'maxico',
}

const australiaSlug = {
  en: 'australia',
  de: 'australien',
  fr: 'australie',
  it: 'australia',
  es: 'australia',
}
const egyptSlug = {
  en: 'egypt',
  de: 'agypten',
  fr: 'egypte',
  it: 'egitto-e-mar-rosso',
  es: 'egipto',
}

const maldivesSlug = {
  en: 'maldives',
  de: 'malediven',
  fr: 'maldives',
  it: 'maldive',
  es: 'maldivas',
}

const belizeSlug = {
  en: 'belize',
  es: 'belice',
}

const getSlug = (slugs, locale) => {
  if(slugs[locale]) {
    return slugs[locale]
  }

  return slugs.en
}

export default function TopDestinations() {
  const { locale } = useRouter()
  return (<div className='blue-bg'>
  <section className={cx(homepageStyles.section, homepageStyles.sectionMargin)}>
    <h2 className={homepageStyles.sectionTitle}><FormattedMessage id='top_destinations' defaultMessage='Top diving destinations' /></h2>
      <p className={homepageStyles.sectionDescription}>
        <FormattedMessage id='book_a_trip' defaultMessage='Book a trip to one of the world’s top diving destinations.' />
      </p>

    <div className={styles.container}>
      <a className={styles.item} href={createUrl('search_la', { slug: getSlug(mexicoSlug, locale) })}>
        <Picture availableSizes={[500]} wepbSrc='/destinations/mexico.webp' alt='Mexico' src='/destinations/mexico.jpg' imageStyles={styles.image} />
        <span className={styles.title}><FormattedMessage id='mexico' defaultMessage='Mexico' /></span>
      </a>
      <a className={styles.item} href={createUrl('search_la', { slug: getSlug(australiaSlug, locale) })}>
        <Picture availableSizes={[500]} wepbSrc='/destinations/australia.webp' alt='Australia' src='/destinations/australia.jpg' imageStyles={styles.image} />
        <span className={styles.title}><FormattedMessage id='australia' defaultMessage='Australia' /></span>
      </a>
      <a className={styles.item} href={createUrl('search_la', { slug: 'bahamas' })}>
        <Picture availableSizes={[500]} wepbSrc='/destinations/bahamas.webp' alt='The Bahamas' src='/destinations/bahamas.jpg' imageStyles={styles.image} />
        <span className={styles.title}><FormattedMessage id='bahamas' defaultMessage='The Bahamas' /></span>
      </a>
      <a className={styles.item} href={createUrl('search_la', { slug: getSlug(egyptSlug, locale) })}>
        <Picture availableSizes={[500]} wepbSrc='/destinations/egypt.webp' alt='Egypt' src='/destinations/egypt.jpg' imageStyles={styles.image} />
        <span className={styles.title}>
          <FormattedMessage id='egypt' defaultMessage='Egypt' />
        </span>
      </a>
      <a className={styles.item} href={createUrl('search_la', { slug: getSlug(maldivesSlug, locale) })}>
        <Picture availableSizes={[500]} wepbSrc='/destinations/maldives.webp' alt='Maldives' src='/destinations/maldives.jpg' imageStyles={styles.image} />
        <span className={styles.title}><FormattedMessage id='maldives' defaultMessage='Maldives' /></span>
      </a>
      <a className={styles.item} href={createUrl('search_la', { slug: getSlug(belizeSlug, locale) })}>
        <Picture availableSizes={[500]} wepbSrc='/destinations/belize.webp' alt='Belize' src='/destinations/belize.jpg' imageStyles={styles.image} />
        <span className={styles.title}><FormattedMessage id='belize' defaultMessage='Belize' /></span>
      </a>
    </div>
  </section>
  </div>)
}
