import PriceConverter from '@/helpers/PriceConverter'
import { Fragment, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import homepageStyles from '../HomePage.module.scss'
import styles from './Destinations.module.scss'
import { Country, ITopDestinationPrices } from '../../../interfaces/FeaturedDestinations'

const Prices = ({ items, isLiveabord }: { items: Country[]; isLiveabord?: boolean}) => {
  if(!items.length) {
    return null
  }

  return <Fragment>
    <p className={styles.destinationsTitle}>{ isLiveabord ? <FormattedMessage id='liveaboards' defaultMessage='Liveaboards' /> : <FormattedMessage id='dive_resorts' defaultMessage='Dive resorts' /> }</p>
    <ul className={styles.destinations}>
    {
      items.map(item => (<li className={styles.destination} key={item.url}>
        <a href={item.url} className={styles.link}>
          <span>{ item.title }</span>
          <span>
            <PriceConverter amount={item.price}/>+ / <FormattedMessage id='day' defaultMessage='day' />
          </span>
        </a>
      </li>))
    }
  </ul>
</Fragment>
}

export default function Destinations({ items }: { items: ITopDestinationPrices[] }) {
  const [activeSection, setActive] = useState<string | boolean>(false)
  const groupedItems = useMemo(() => {
    const maxColumns = items.length > 8 ? 4 : Math.round(items.length / 2)
    return [...Array(maxColumns).keys()].map(c => items.filter((_, i) => i % maxColumns === c))
  }, [items])

  const setActiveSection = (section: string): void => {
    if(activeSection === section) {
      return setActive(false)
    }

    setActive(section)
  }

  return <div className={homepageStyles.section}>
    <h2 className={homepageStyles.sectionTitle}>
      <FormattedMessage id='travel_destinations' defaultMessage='PADI Travel destinations' />
    </h2>
    <div className={styles.wrapper}>
    {
      groupedItems.map((group, index) => {
        return <div className={styles.column} key={index}>
          {
            group.map(country => {
              const handleClick = () => setActiveSection(country.title)
              return <div key={country.title} onClick={handleClick} className={cx(styles.item, { [styles.active]: country.title === activeSection })}>
              <p className={styles.title}>{country.title}</p>
                <div className={styles.destinationsWrapper}>
                  <Prices items={country.liveaboardCountries} isLiveabord />
                  <Prices items={country.diveresortCountries} />
                </div>
              </div>
            })
          }
          </div>
      })
    }
    </div>
    <div className={styles.usefulLinksSection}>
      <h2 className={styles.usefulLinksHeader}>
        <FormattedMessage id='travel_destinations_useful_information' defaultMessage='Useful information for scuba diving and travel' />
      </h2>
      <a className={homepageStyles.link} href='https://blog.padi.com/covid-19-diving-travel-restrictions-by-country/'>
        <FormattedMessage defaultMessage='Travel resources' />
        <i className={cx(homepageStyles.rightArrowIcon, 'font-icons right-arrow-icon')} />
      </a>
    </div>
  </div>
}
