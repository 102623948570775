import { useState } from 'react'
import SearchLocationSubItemsList from './SearchLocationSubItemsList'
import { connect } from 'react-redux'
import { showLoader } from '@/actions/main-actions'
import { trackClick } from '@/helpers/gtm'
import styles from './TravelSearch.module.scss'
import cx from 'classnames'
import {
  ConservationAutosuggestData,
  DiveGuideAutosuggestData,
  Fleet,
  Gplace,
  Place,
} from '@/components/homepage/hero/search-autosuggest/interfaces'

interface SearchLocationProps {
  location: Gplace | Fleet | Place | DiveGuideAutosuggestData | ConservationAutosuggestData
  isFirstRow: boolean
  isDiveGuides: boolean
  isConservation: boolean

  showLoader(state: boolean): void
}

const URL_LINKS = [
  'liveaboardSearchUrl',
  'diveResortSearchUrl',
  'diveCenterSearchUrl',
  'diveSearchUrl',
  'snorkellingSearchUrl',
  'courseSearchUrl',
  'diveShopUrl',
  'diveSiteUrl',
  'diveGuideUrl',
  'conservationSearchUrl',
  'travelAutosuggestFleetsUrl',
]

const SearchLocation = ({
  location,
  isFirstRow,
  showLoader,
  isDiveGuides,
  isConservation,
}: SearchLocationProps) => {
  const urlField = URL_LINKS.find((name) => location[name])
  const { diveSearchUrl, courseSearchUrl, snorkellingSearchUrl } = location as Place
  const adventuresUrl =
    diveSearchUrl || courseSearchUrl || snorkellingSearchUrl
  const [isActive, setActive] = useState(isFirstRow)
  const toggleActive = () => {
    setActive(!isActive)
  }
  const handleLocationClick = () => {
    (document.activeElement as HTMLDivElement).blur()
    showLoader(true)
    const link = location[urlField]

    trackClick({ regionIdentifier: 'search-auto-complete', link })

    window.location.href = link
  }
  return (
    <div className='travel-search-container__list'>
      <div className={styles.row}>
        <div
          className={cx(styles.location, 'search-row')}
          onClick={handleLocationClick}
        >
          <i className={cx(styles.rowLocationIcon, 'font-icons location-icon')} />
          <div className={styles.locationWrapper}>
            <span className={styles.locationTitle}>
              {location.title}
            </span>
            <span className={styles.locationAddress}>
              {(location as Place).formattedAddress}
            </span>
          </div>
        </div>
        <i
          className={cx(styles.arrowIcon, isActive ? 'top-arrow-icon' : 'bottom-arrow-icon', 'font-icons')}
          onClick={toggleActive}
        />
      </div>
      {isActive && (
        <SearchLocationSubItemsList
          liveaboardSearchUrl={(location as Place).liveaboardSearchUrl}
          diveResortSearchUrl={(location as Place).diveResortSearchUrl}
          diveCenterSearchUrl={(location as Place).diveCenterSearchUrl}
          adventuresUrl={adventuresUrl}
          diveShopUrl={(location as DiveGuideAutosuggestData).diveShopUrl}
          diveSiteUrl={(location as DiveGuideAutosuggestData).diveSiteUrl}
          conservationSearchUrl={(location as ConservationAutosuggestData).conservationSearchUrl}
          isDiveGuides={isDiveGuides}
          isConservation={isConservation}
          guideUrl={(location as DiveGuideAutosuggestData).diveGuideUrl}
        />
      )}
    </div>
  )
}

const mapDispatchToProps = { showLoader }

export default connect(null, mapDispatchToProps)(SearchLocation)
