import { Fragment, useMemo } from 'react'

const getImagePath = (src: string) => {
  if(process.env.NODE_ENV === 'production') {
    return `${process.env.NEXT_PUBLIC_AWS_S3_CUSTOM_DOMAIN}/next-images${src}`
  }

  return `/next-images${src}`
}

const srcConfig = [500, 768, 1024]
const MIME_TYPES = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  webp: 'image/webp',
  png: 'image/png',
  svg: 'image/svg+xml',
}

const getSrcSet = (imagePath: string, config:number[]) => {
  if(!config.length) {
    return null
  }
  return config.reduce((acc, current) => acc + `${getImagePath(imagePath).replace(/.([^.]*)$/, `_${current}.$1`)} ${current}w,`, '').slice(0, -1)
}

const getSizes = (config) => {
  if(!config.length) {
    return null
  }
  return config.reduce((acc, current) => acc + `(max-width: ${current}) ${current}px,`, '').slice(0, -1)
}

const GetSrcSetSources = (imagePath: string, config: number[]) => {
  const type = MIME_TYPES[imagePath.split('.').pop()]
  const sources = useMemo(() => {
    return config.map((src) => {
      const path = getImagePath(imagePath)

      return <source key={src} media={`(max-width: ${src}px)`} srcSet={path.replace(/.([^.]*)$/, `_${src}.$1`)} type={type} />
    })
  }, [config, imagePath, type])
  return (<Fragment>
    {sources}
    <source srcSet={getImagePath(imagePath)} type={type} />
  </Fragment>)
}

interface IPicture {
  src: string
  wepbSrc?: string
  imageStyles?: string
  pictureStyles?: string
  alt: string
  loading?: 'lazy' | 'eager'
  availableSizes?: number[]
  width?: string | number
  height?: string | number
}

export default function Picture({ src, wepbSrc, imageStyles, pictureStyles, alt, loading = 'lazy', availableSizes = srcConfig, width, height }: IPicture) {
  return (<picture className={pictureStyles}>
    {wepbSrc && GetSrcSetSources(wepbSrc, availableSizes)}
    {GetSrcSetSources(src, availableSizes)}
    <img loading={loading}
      src={getImagePath(src)}
      alt={alt}
      width={width}
      height={height}
      srcSet={getSrcSet(src, availableSizes)}
      sizes={getSizes(availableSizes)}
      className={imageStyles} />
  </picture>)
}
