import { Fragment } from 'react'

type MetaTagsProps = {
  title: string
  description?: string
  image?: string
}

export default function MetaTags({ title, description, image = 'https://d2p1cf6997m1ir.cloudfront.net/static/travel_dist/images/padi_travel_social_default.jpg' }: MetaTagsProps) {
  return (<Fragment>

    <meta itemProp="name" content={title} />
    {description && (<Fragment>
      <meta name="description" content={description} />
      <meta itemProp="description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta property="og:description" content={description} />
    </Fragment>)}
    <meta property="og:title" content={title} />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />

    <meta property="og:image" content={image} />
    <meta name="twitter:image" content={image} />
    <meta property="og:image" content={image} />
  </Fragment>)
}
