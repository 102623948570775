import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import SearchLocationSubItem from './SearchLocationSubItem'

type SearchLocationSubItemsListProps = {
  liveaboardSearchUrl: string
  diveResortSearchUrl: string
  diveCenterSearchUrl: string
  guideUrl: string
  adventuresUrl: string
  diveShopUrl: string
  diveSiteUrl: string
  conservationSearchUrl: string
  isDiveGuides: boolean
  isConservation: boolean
}

const SearchLocationSubItemsList = ({
  liveaboardSearchUrl,
  diveResortSearchUrl,
  diveCenterSearchUrl,
  guideUrl,
  adventuresUrl,
  diveShopUrl,
  diveSiteUrl,
  conservationSearchUrl,
  isDiveGuides,
  isConservation,
}: SearchLocationSubItemsListProps) => {
  return (
    <Fragment>
      {liveaboardSearchUrl && (
        <SearchLocationSubItem
          icon='liveaboard-icon'
          title={
            <FormattedMessage id='liveaboards' defaultMessage='Liveaboards' />
          }
          isDiveGuides={isDiveGuides}
          isConservation={isConservation}
          url={liveaboardSearchUrl}
        />
      )}
      {diveResortSearchUrl && (
        <SearchLocationSubItem
          icon='dive-resort-icon'
          title={
            <FormattedMessage id='dive_resorts' defaultMessage='Dive resorts' />
          }
          isDiveGuides={isDiveGuides}
          isConservation={isConservation}
          url={diveResortSearchUrl}
        />
      )}
      {diveSiteUrl && (
        <SearchLocationSubItem
          icon='icon-dive-sites'
          title={<FormattedMessage id='dive_site' defaultMessage='Dive site' />}
          isDiveGuides={isDiveGuides}
          isConservation={isConservation}
          url={diveSiteUrl}
        />
      )}
      {diveShopUrl && (
        <SearchLocationSubItem
          icon='icon-dive-center'
          title={<FormattedMessage id='dive_shop' defaultMessage='Dive shop' />}
          isDiveGuides={isDiveGuides}
          isConservation={isConservation}
          url={diveShopUrl}
        />
      )}
      {diveCenterSearchUrl && (
        <SearchLocationSubItem
          icon='dive-center-icon'
          title={
            <FormattedMessage id='dive_centers' defaultMessage='Dive centers' />
          }
          isDiveGuides={isDiveGuides}
          isConservation={isConservation}
          url={diveCenterSearchUrl}
        />
      )}
      {adventuresUrl && (
        <SearchLocationSubItem
          icon='icon-diver'
          title={
            <FormattedMessage id='adventures' defaultMessage='Adventures' />
          }
          isDiveGuides={isDiveGuides}
          isConservation={isConservation}
          url={adventuresUrl}
        />
      )}
      {conservationSearchUrl && (
        <SearchLocationSubItem
          icon='icon-diver'
          title={
            <FormattedMessage
              id='conservation_activities'
              defaultMessage='Conservation activities'
            />
          }
          isDiveGuides={isDiveGuides}
          isConservation={isConservation}
          url={conservationSearchUrl}
        />
      )}
      {guideUrl && (
        <SearchLocationSubItem
          icon='icon-information'
          title={
            <FormattedMessage id='dive_guide' defaultMessage='Dive guide' />
          }
          isDiveGuides={isDiveGuides}
          isConservation={isConservation}
          url={guideUrl}
        />
      )}
    </Fragment>
  )
}

export default SearchLocationSubItemsList
