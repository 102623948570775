import { useDispatch } from 'react-redux'
import { showLoader } from '@/actions/main-actions'
import { trackClick } from '@/helpers/gtm'
import cx from 'classnames'
import styles from './TravelSearch.module.scss'

type SearchLocationSubItemProps = {
  icon: string
  title: string | JSX.Element
  url: string
  isDiveGuides?: boolean
  isConservation?: boolean
}
const SearchLocationSubItem = ({
  icon,
  title,
  url,
  isDiveGuides,
  isConservation,
}: SearchLocationSubItemProps) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    (document.activeElement as HTMLDivElement).blur()
    dispatch(showLoader(true))
    trackClick({ regionIdentifier: 'search-auto-complete', link: url })
    window.location.href = url
  }
  return (
    <div
      className={cx(styles.subRow, 'search-row')}
      onClick={handleClick}
    >
      <div className={styles.subRowContent}>
        <i className={cx(styles.icon, styles[icon], `font-icons ${icon}`)} />
        {title}
      </div>
    </div>
  )
}

export default SearchLocationSubItem
