/* eslint-disable quotes */
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import { useSelector } from 'react-redux'

import homepageStyles from '../HomePage.module.scss'
import styles from './ContactUs.module.scss'
import IndividualBooking from '@/components/shared/IndividualBooking'
import Picture from '@/helpers/Picture'
import TrustPilotBlock from '@/helpers/TrustPilotBlock'
import { target } from '@/helpers/utils'
import { RootState } from 'redux/store'

export default function ContactUs() {
  const phone = useSelector((state: RootState) => state.phoneNumber)
  return (<div className='blue-bg'>
    <section className={cx(homepageStyles.section, styles.section)}>
      <h2 className={homepageStyles.sectionTitle}><FormattedMessage id='contact_us' defaultMessage='Contact us' /></h2>
      <p className={cx(homepageStyles.sectionDescription, styles.sectionDescription)}>
        <FormattedMessage id='contact_us.text' defaultMessage={`Our website is designed to help you find what you need to book a great trip. If you can't find what you're looking for, let us know.`} />
      </p>

      <ul className={styles.list}>
        <li className={cx(styles.listRow)}>
          <i className={cx(styles.envelopIcon, styles.icon, 'font-icons envelop-icon')}></i>
          <IndividualBooking />
        </li>
        <li className={cx(styles.listRow, 'mkt_footer_live_chat livechat-button')}>
          <i className={cx(styles.chatIcon, styles.icon, 'font-icons chat-icon')}></i>
          <span
            className={styles.link}
            data-navigation-elements='data-navigation-elements'
            data-fake-url='live-chat'
            data-region-identifier='menu-travelmain-contactus'>
            <FormattedMessage id='start_live_chat' defaultMessage='Start a live chat' />
          </span>
        </li>
        <li className={cx(styles.listRow)}>
          <i className={cx(styles.phoneIcon, styles.icon, 'font-icons phone-icon')} />
          <a href={`tel:${phone}`}
              className={styles.link}
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-travelmain-contactus'>
              <FormattedMessage id='call_us_at' defaultMessage='Call us at' />&nbsp;{phone}
          </a>
        </li>
        <li className={cx(styles.listRow)}>
          <i className={cx(styles.infoIcon, styles.icon, 'font-icons icon-information')} />
          <a href='https://padi.force.com/travelhelp/s/'
             target={target}
             className={styles.link}
             data-navigation-elements='data-navigation-elements'
             data-region-identifier='menu-travelmain-contactus'>
            <FormattedMessage id='contact_us_help_center' defaultMessage='Help Center' />
          </a>
        </li>
      </ul>
      <Picture availableSizes={[]} pictureStyles={styles.picture} wepbSrc='/team.webp' alt='Team picture' src='/team.png' imageStyles={styles.image} width='100%' height='100%' />
    </section>
    <TrustPilotBlock />
  </div>)
}
